<template>
 <div id="login">
        <!-- <div class="mask"></div> -->
        <div class="back" v-on:click="backClick"><img class="icon" src="../assets/icon/back.png"/></div>
        <div class="login-form">
             <div class="form-group" style="text-align: center;">
                <img class="logo" src="@/assets/logo1.png"/>
            </div>
            <div class="form-title">
                <span class="title">验证码登录</span>
                <span class="subtitle">新用户登陆后将自动创建账号</span>
            </div>
            <div class="form-group">
                <input type="number" class="form-input" v-model="account" placeholder="请输入手机号"/>
            </div>
            <div class="form-group" style="display:flex;">
                <input type="password" class="form-input" style="border-radius:2.8vh 0px 0px 2.8vh;;border-right:0"  v-model="code" placeholder="请输入验证码" maxlength="100">
                <button class="bt code" :style="{background:(canGetCode?'#ff3333':'#ccc')}" v-on:click="sendCode">{{codeText}}</button>
            </div>
             <div class="form-group agreement">
                 <input type="checkbox" v-model="agreementChecked"/><label class="text">注册或登陆表示同意平台</label><a href="/agreement" class="link">用户协议</a>
            </div>
            <div wx:if="isInWeXin" class="form-group" style="display: flex;justify-content: center;" v-on:click="wxLogin">
              <img src="@/assets/icon/WX.png" style="width:10vw;height:10vw;"/>
            </div>
            <div class="form-group" style="text-align:center;margin-top:0px;">
                 <div class="btn" v-on:click="login">登录</div>
            </div>
        </div>
        <!-- <div class="footer">
            <div class="back icon-kaistart-arrows-left width-19" v-on:click="back"></div>
        </div> -->
    </div>
</template>
<script>
import http from '../utils/http'
export default {
    name: 'login',
    computed:{

    },
    data () {
        return {
            appId:'wxe39df9727d3d3782',
            appSecret:'9cd6de7ee9fe3d05c4790a5b144e10c2',
            // appId:'wx6024f19787e302d7',
            // appSecret:'385c33c92c3455d98238d6ae5759f41c',
            loginWay:'scope',
            codeText:'获取验证码',
            canGetCode:true,//获取中
            account:'',
            code:'',
            timers:30,
            agreementChecked:false,
            isInWeXin:false
        }
    },
    methods: {
        wxLogin:function(){
            if(!this.agreementChecked){
                this.message.info('请阅读并同意用户协议');
                return;
            }
            // var redirectUriWx='https://m.keyuexue.cn/login';
            var redirectUriWx='https://m.iotmobile.cn/login';
            // this.wxToken();
            let url = encodeURIComponent(redirectUriWx);  // 注意一定要encodeURIComponent
            var state=1;
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&&state=${state}#wechat_redirect';`
            // wx.updateAppMessageShareData({ 
            //     title: '测试', // 分享标题
            //     desc: '测试', // 分享描述
            //     link: '测试', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            //     imgUrl: '测试', // 分享图标
            //     success: function () {
            //         console.log('分享成功')
            //     // 设置成功
            //     }
            // })
        },
        backClick:function(){
            this.$router.push({name:'index'});
        },
        login: function () {
            if(this.account==''){
                this.message.info('请输入手机号');
                return;
            }
             if(this.code==''){
                this.message.info('请输入验证码');
                return;
            }
            if(!this.agreementChecked){
                this.message.info('请阅读并同意用户协议');
                return;
            }
            var channel=this.$store.state.user.channel;
            var share=this.$store.state.user.share;
            this.$store.dispatch('user/login',{Phone:this.account,Code:this.code,Channel:channel,Share:share,success:(result)=>{
                    this.$store.dispatch('user/getUserInfo');
                    history.back();
                },
                error:(msg)=>{
                    this.message.info('登陆失败'+msg);
                }
            });
        },
        timer: function () {
                if (this.timers > 0) {
                    this.codeText=this.timers;
                    this.timers--;
                }
                else{
                    this.codeText="获取验证码";
                    this.canGetCode=true;
                }
        },
        sendCode:function(){
            if(!this.canGetCode){
                return;
            }
            http.get("/api/auth/sendcode?phone="+this.account,(data)=>{
                if (data.Success) {
                    this.timers=30;
                    this.canGetCode=false;
                    setInterval(this.timer, 1000);
                }
                else{
                    alert('登陆失败'+data.Message);
                }
            },(e)=>{
                console.log(e);
            });
        },
        back: function () {
            this.$router.push({ name: 'index'});
        },
        wxToken(){
            
        },
        getWeChatConfig(redirectUriWx){
            return new Promise((resove,reject)=>{
                http.post("/Api/Wechat/Public/Share",{
                    appID:this.appId,
                    url:redirectUriWx,
                },(data)=>{
                    if (data.Success) {
                        resove(data.Data);
                    }
                    else{
                        resove(data.Message);
                    }
                },(e)=>{
                    resove(e);
                });
            });
        },
        getCode () {
            let url = location.search;
            let theRequest = new Object()
            if (url.indexOf("?") != -1) {
                let str = url.substr(1)
                let strs = str.split("&")
                for(let i = 0; i < strs.length; i ++) {
                    theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
                }
            }
            return theRequest;
        },
    },
    created: function () {
        //this.loadProfit();
    },
    mounted: function () {//dom已渲染
        //如果来自微信回调
        // alert(JSON.stringify(this.getCode()))
        var ua = navigator.userAgent.toLowerCase()
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            //在微信中打
            //document.querySelector('.zfb').style.display="none";
            this.isInWeXin=true;
        }
        if(this.$route.query.code){
            http.post("/Api/Wechat/Public/UserInfo",{
                appID:this.appId,
                code:this.$route.query.code,
            },(data)=>{
                if (data.Success) {
                    data.Data.success=(result)=>{
                        this.message.info('微信登陆成功');
                        this.$store.dispatch('user/getUserInfo');
                        //判断路由记录跳转到之前的页面
                        this.$router.push({ name: 'personal'})
                    };
                    data.Data.error=(msg)=>{
                         this.message.info('微信登陆异常');
                    }
                    this.$store.dispatch('user/setLoginInfo',data.Data);
                }
                else{
                    console.log(data);
                }
            },(e)=>{
                this.message.info('微信登陆异常');
            });
        }
    },
}
</script>
<style lang="css" scoped>
   *{
       font-family: "Helvetica Neue",helvetica,arial,sans-serif;
   }
   #login{
        background:#fff;
        height: 100vh;
        overflow: hidden;
        background-size: cover;
   }
   #login .logo{
        height: 10vh;
        border-radius: 2vw;
   }
   #login .mask{
        height: 100vh;
        width: 100vw;
        background: rgba(0,0,0,0);
        position: absolute;
        z-index: 1;
   }
   .back{
        position: absolute;
        top: 0;
        z-index: 999;
        width: 13.3vw;
        height: 13.3vw;
        /* background: red; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .back .icon{
        height: 16px;
    }
   .login-form{
        /* position: absolute; */
        padding:60px 30px 30px 30px;
        height: 100vh;
        /* z-index: 2; */
   }
   .form-title{
        padding: 20px 0;
        font-size: 14px;
        color: #333;
        display: flex;
        flex-direction: column;
   }
   .form-title .title{
        font-weight: bold;
        font-size: 7.733vw;
        padding:15px 0;
   }
   .form-title .subtitle{
        font-size: 16px;
   }
   .codebtn{
       width: 99px;
   }
   .form-input{
        outline-style: none;
        border: 1px solid #eee;
        border-radius:1.4rem;
        box-shadow: none;
        padding: 1.4rem 0;
        height: 1rem;
        width: 100%;
        color: #666;
        font-weight: 500;
        font-size: 1rem;
        text-indent: 1rem;
        -webkit-appearance: none;
        box-sizing: border-box;
   }
   .form-input:focus{
        border-color: #ff3333;
        outline: 0;
    }
   .form-group{
        width: 100%;
        padding: 2.0vh 0px;
        height: auto;
    }
    .form-control{
        font-size:14px;
        vertical-align: middle;
        color:#333;
        padding: 2.0vh;
        background: #fff;
    }
.agreement{
    display: flex;
    align-items: center;
}
.agreement .text{
    font-size: 14px;
    margin-left:10px;
    color: #333;
}
.agreement .link{
    font-size: 14px;
    /* margin-left:10px; */
    font-size: 14px;
    color:"blue";
}
.bt {
     display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #ff3333;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    border-radius:2.8vh;
    text-align: center;
}
.code{
    border-radius: 0 2.8vh 2.8vh 0;
    width:40vw;
    box-sizing: border-box;
    border: 1px solid #ff3333;
}
.btn{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff3333;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    font-size: 14px;
    font-weight: 700;
    padding: 2.0vh 0;
    color: #fff;
    letter-spacing: 0;
    border-radius: 1.0vh;
    text-align: center;
}
.bt.disabled {
    background-color: #d8d7d8;
    color: #989998
}
</style>
